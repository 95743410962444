<template>
  <div>
    <v-card class="pa-0 tab-item-card" flat>
      <v-img id="summary-img" src="../../assets/agreement-summary.jpg"></v-img>
      <div class="text-center py-12">
        <span class="text-h3 text-sm-h2 text-md-h1">Agreement summary</span>
      </div>
      <v-container class="px-5 py-5">
        <p class="text-h3 text-sm-h2 text-md-h1">Renting 20 Hunter Street</p>
        <p class="text-h6 text-sm-h4">For 6 months at £695 pcm rent</p>
      </v-container>
      <div class="primary darken-4 text-center py-12">
        <v-container class="px-5 py-8">
          <p class="white--text text-h4">Lets get your tenancy agreement drafted and signed!</p>
          <v-btn @click="$router.push('responsibility')" color="secondary" width="200" class="py-5">start</v-btn>
        </v-container>
      </div>
    </v-card>
    <MainPagination 
      previousPage="/review/sign"
      nextPage="/review/responsibility"
    />
  </div>
</template>

<script>
export default {
  name: 'agreement-summary'
}
</script>

<style>

</style>